import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetSubDepartmentDTO } from "common/models/subDepartment.model";

interface SubDepartmentState {
    loading: boolean
    listSubDepartment: IGetSubDepartmentDTO[],
    message: string
}

const initSubDepartmentState: SubDepartmentState = {
    loading: false,
    listSubDepartment: [],
    message: ""
}

const subDepartmentSlice = createSlice({
    name: 'subDepartment',
    initialState: initSubDepartmentState,
    reducers: {
        getSubDepartmentRequest(state, action: PayloadAction<string>) {
            state.loading = true
        },
        getSubDepartmentSuccess(state, action: PayloadAction<IGetSubDepartmentDTO[]>) {
            state.listSubDepartment = action.payload
            state.loading = false
        },
        messageForgot(state, action: PayloadAction<string>) {
            state.message = action.payload
        }
    }
})

export const {
    getSubDepartmentRequest,
    getSubDepartmentSuccess,
    messageForgot
} = subDepartmentSlice.actions;


export const subDepartmentReducer = subDepartmentSlice.reducer
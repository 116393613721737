import React, { Suspense } from "react";
import { RouterItem } from "common/define-type";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CLoading from "./CLoading";
import Auth from "pages/auth/auth";
// import CPrivateRoute from "./CPrivateRouter";

const MainModule = React.lazy(() => import("pages/main"))
const PageNotFound = React.lazy(() => import("pages/404"))
const LoginModule = React.lazy(() => import("pages/login/Login"))

const RouterArr: RouterItem[] = [
    {
        path: "/main",
        component: MainModule,
        noExact: true
    },
    {
        path: "/login",
        component: LoginModule
    },
    {
        path: "/",
        component: LoginModule
    },
    {
        path: "*",
        component: PageNotFound
    }

]

export default function CMainRouter(): JSX.Element {
    return (
        <Router>
            <Auth>
                <Suspense fallback={<CLoading visible={true} fullScreen={true} />}>
                    <Switch>
                        {RouterArr.map(({ path, component: Component, noExact, ...rest }) => {
                            return <Route path={path} component={Component} key={path} exact={noExact ? false : true} {...rest} />
                        })}
                    </Switch>
                </Suspense>
            </Auth>
        </Router >
    )
}
import Utils from "common/utils"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
const Auth = ({ children }: Props): JSX.Element => {
    const history = useHistory()
    useEffect(() => {
        const token = Utils.getValueLocalStorage("token")
        if (token) {
            if(history.location.pathname === "/login"){
                history.push("/main")
            }
        } else {
            history.push("/login")
        }
    }, [history])
    return (
        <React.Fragment>{children}</React.Fragment>
    )
}
export default Auth
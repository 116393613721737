import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootEpic, SystemConfig } from "common/define-type";
import { filter, map, switchMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import IdentityApi from "api/identity/identity.api"
import DepartmentApi from "api/department/department.api";
import SubDepartmentApi from "api/subDepartment/subDepartment.api";
import EmployeeApi from "api/employee/employee.api";
import AppApi from "api/app";
import ApplicantApi from "api/applicant/applicant.api";
import TimeOffItemApi from "api/timeOffItem/timeOffItem.api";
import ReasonTimeOffApi from "api/reasonTimeOff/reasonTimeOff.api";

interface BootstrapState {
    systemConfig: SystemConfig;
    isSuccess: boolean
}
const PATH_SYSTEM_CONFIG = `${process.env.PUBLIC_URL}/assets/system-config.json`;
const IS_CONFIG_LOCAL = false;
const DEFAULT_CONFIG: SystemConfig = {
    hostIdentity: 'https://tcs.anybim.vn',
    hostOsTCS: "https://tcs.anybim.vn"
};
const initialStateBootstrap: BootstrapState = {
    systemConfig: DEFAULT_CONFIG,
    isSuccess: false,
};

function updateHostService(host: SystemConfig) {
    IdentityApi.host = host.hostIdentity;
    DepartmentApi.host = host.hostOsTCS;
    SubDepartmentApi.host = host.hostOsTCS;
    EmployeeApi.host = host.hostOsTCS;
    AppApi.host = host.hostOsTCS;
    ApplicantApi.host = host.hostOsTCS;
    TimeOffItemApi.host = host.hostOsTCS;
    ReasonTimeOffApi.host = host.hostOsTCS;
}
const bootstrapSlice = createSlice({
    name: 'bootstrap',
    initialState: initialStateBootstrap,
    reducers: {
        setSysytemConfig: (state, action: PayloadAction<SystemConfig>) => {
            updateHostService(action.payload);
            state.systemConfig = action.payload;
            state.isSuccess = true;
        },
        fetchConfig: (state) => {
            state.isSuccess = false;
        }
    }
})

const bootstrap$: RootEpic = (action$) => action$.pipe(
    filter(fetchConfig.match),
    switchMap(() => {
        return ajax.getJSON(PATH_SYSTEM_CONFIG, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }).pipe(map(res => {
            const config = IS_CONFIG_LOCAL ? DEFAULT_CONFIG : res as SystemConfig;
            return bootstrapSlice.actions.setSysytemConfig(config)
        }))
    })
);


export const BoostrapEpics = [
    bootstrap$
];

export const { fetchConfig } = bootstrapSlice.actions;
export const bootstrapReducer = bootstrapSlice.reducer;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, parseISO } from "date-fns";
import moment from "moment";

class Utils {
    static setLocalStorage(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
    static getValueLocalStorage(key: string): any | null {
        const value = localStorage.getItem(key);
        let re = null;
        value && (re = Utils.parseJson(value));
        return re;
    }
    static removeItemLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }

    static parseJson(str: string): any | null {
        try {
            return JSON.parse(str);
        } catch (e) {
            return null;
        }
    }
    static pathNameMatchContent(path: string): string | undefined {
        if (path === "/main") {
            return "main";
        }
        const regex = /\/main\/(\w+)$/g;
        const matchResult = regex.exec(path);
        if (matchResult && matchResult.length === 2) {
            return matchResult[1];
        }
        return;
    }
    static formatDate(date: Date): any | null {
        const tyoeFormat = "MM/dd/yyyy";
        return format(date, tyoeFormat);
    }
    static formatDateVN(date: Date): any | null {
        const tyoeFormat = "dd/MM/yyyy";
        return format(date, tyoeFormat);
    }
    static formatDateVNPlusTime(date: Date): any | null {
        const tyoeFormat = "dd/MM/yyyy HH:mm";
        return format(date, tyoeFormat);
    }
    static formatDateCallApi(date: Date): any | null {
        const tyoeFormat = "yyyy-MM-dd";
        return format(date, tyoeFormat);
    }
    static formatDateTimeCallApi(date: Date): any | null {
        const tyoeFormat = "yyyy-MM-dd HH:mm:ss";
        return format(date, tyoeFormat);
    }
    static isNullOrEmpty(value: string | null): boolean {
        return value === null || value === "" || value === undefined;
    }

    static formatDateToShortString(date: string): string {
        if (!date) return '';
        const isValidDate  = Date.parse(date);
        if (isNaN(isValidDate)) return '';
        return format(isValidDate, 'dd/MM/yyyy');
    }

    static formatDateToString(date: string): string {
        if (!date) return '';
        const isValidDate  = Date.parse(date);
        if (isNaN(isValidDate)) return '';
        return format(isValidDate, 'dd/MM/yyyy HH:mm');
    }

		static customeFormatDateToString(date: string, formatString: string): string {
			if (!date) return '';
			const isValidDate  = Date.parse(date);
			if (isNaN(isValidDate)) return '';
			return format(isValidDate, formatString);
	}

    static formatDateString(date: string): any | null {
        const tyoeFormat = "dd/MM/yyyy";
        const newDate = parseISO(date);
        return format(newDate, tyoeFormat);
    }
    static typeFormatDate(): string {
        return "dd/MM/yyyy";
    }
    static typeFormatYear(): any | null {
        return "yyyy";
    }
    static formatDateToTable(dateArr: string, dateDep: string): string | null {
        const tyoeFormat = "MM/dd";
        const newDateArr = parseISO(dateArr);
        const newDateDep = parseISO(dateDep);
        return (
            format(newDateArr, tyoeFormat) +
			" - " +
			format(newDateDep, tyoeFormat)
        );
    }
    static formatDateToStringRevenue(dateArr: string): string | null {
        const tyoeFormat = "dd/MM";
        const newDateArr = parseISO(dateArr);
        return format(newDateArr, tyoeFormat);
    }
    static convertToAsiaVNZone(date: Date): string {
        const tzString = "Asia/Jakarta";
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString(
                "en-US",
                { timeZone: tzString }
            )
        ).toISOString();
    }

    static convertToVNTimeZone(date: Date): string {
        const hour = 7;
        date.setTime(date.getTime() + hour * 60 * 60 * 1000);
        return date.toISOString();
    }
    static convertToVNTimeZoneMbyMoment(date: string | Date): string {
        const testDateUtc = moment.utc(date);
        const localDate = moment(testDateUtc).local();
        return localDate.format("MM-DD-YYYY HH:mm:ss");
    }
    static convertToUTC(date: Date): string {
        return moment(date).utc().format();
    }
    static differenceInDays(
        dateTo: Date | string,
        dateForm: Date | string
    ): number {
        return moment(dateTo).diff(moment(dateForm), "days");
    }
    static convertBirthDateFormat(oldDate: string | null): Date | undefined {
        if (oldDate === null || oldDate === undefined) return undefined;
        if (oldDate.length > 9) {
            return new Date(oldDate);
        }
        return undefined;
    }
    static convertStartDate(date: Date | string): any {
        const tmp = new Date(date);
        tmp.setHours(0, 0, 0, 0);
        return tmp;
    }

    static convertMiddleDate(date: Date | string): any {
        const tmp = new Date(date);
        tmp.setHours(12, 0, 0, 0);
        return tmp;
    }

    static convertEndDate(date: Date | string): any {
        const tmp = new Date(date);
        tmp.setHours(23, 59, 59, 59);
        return tmp;
    }
    static formatNumber(value: number): string {
        const stringFormat = this.getValueLocalStorage("FMNUMBER");
        return Intl.NumberFormat(stringFormat ?? "en-US").format(value ?? 0);
    }

    static unsetFormatNumber(value: string): number {
        return parseInt(value.split(",").join(""));
    }

	static dateDiffInDays = (startDate: Date, endDate: Date): number => {
	    // Discard the time and time-zone information.
	    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
	    const utc1 = Date.UTC(
	        startDate.getFullYear(),
	        startDate.getMonth(),
	        startDate.getDate()
	    );
	    const utc2 = Date.UTC(
	        endDate.getFullYear(),
	        endDate.getMonth(),
	        endDate.getDate()
	    );
	    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static transformData = (obj: any, destination: any) => {
	    Object?.keys(destination)?.map((key) => {
	        if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)
	            destination[key] = obj[key];
	        return null;
	    });
	};
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static querySearchToString(filters: any): string {
	    const _filters = { ...filters };
	    const searchParams = Object.keys(_filters)
	        .map((filterKey) => {
	            if (
	                _filters[filterKey] === undefined ||
					_filters[filterKey] === null
	            ) {
	                return "";
	            }
	            return `${filterKey}=${_filters[filterKey]}`;
	        })
	        .join("&");
	    return searchParams;
	}
	static querySearchToJson(queryString: string): any {
	    const pairs = queryString.substring(1).split("&");
	    const array = pairs.map((el) => {
	        const parts = el.split("=");
	        return parts;
	    });

	    return Object.fromEntries(array);
	}
	static getPageSize(windowSize: string): number {
	    switch (windowSize) {
	    case "2xl":
	        return 15;
	    case "xl":
	        return 12;
	    case "lg":
	        return 12;
	    case "md":
	        return 12;
	    case "sm":
	        return 12;
	    default:
	        break;
	    }
	    return 12;
	}
	static getPageSizeAssign(windowSize: string): number {
	    switch (windowSize) {
	    case "2xl":
	        return 8;
	    case "xl":
	        return 6;
	    case "lg":
	        return 6;
	    case "md":
	        return 6;
	    case "sm":
	        return 6;
	    default:
	        break;
	    }
	    return 6;
	}

	static compareString = (a: string, b: string): number => {
	    return a.localeCompare(b);
	};

	static spliceSlice(str: string, index: number, count: number, add: string) {
	    // We cannot pass negative indexes directly to the 2nd slicing operation.
	    if (index < 0) {
	        index = str.length + index;
	        if (index < 0) {
	            index = 0;
	        }
	    }

	    return str.slice(0, index) + (add || "") + str.slice(index + count);
	}

	static compareWithoutTime = (date1: Date, date2: Date): boolean => {
	    date1.setHours(0, 0, 0, 0);
	    date2.setHours(0, 0, 0, 0);
	    return date1.toDateString() === date2.toDateString();
	};

	static middayTime(date: Date): Date {
	    date.setHours(12, 0, 0, 0);
	    return date;
	}

	static removeAccents(str: string): string {
	    const AccentsMap = [
	        "aàảãáạăằẳẵắặâầẩẫấậ",
	        "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
	        "dđ",
	        "DĐ",
	        "eèẻẽéẹêềểễếệ",
	        "EÈẺẼÉẸÊỀỂỄẾỆ",
	        "iìỉĩíị",
	        "IÌỈĨÍỊ",
	        "oòỏõóọôồổỗốộơờởỡớợ",
	        "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
	        "uùủũúụưừửữứự",
	        "UÙỦŨÚỤƯỪỬỮỨỰ",
	        "yỳỷỹýỵ",
	        "YỲỶỸÝỴ",
	    ];
	    for (let i = 0; i < AccentsMap.length; i++) {
	        const re = new RegExp("[" + AccentsMap[i].substring(1) + "]", "g");
	        const char = AccentsMap[i][0];
	        str = str.replace(re, char).toUpperCase();
	    }
	    return str;
	}

	static formatCreditCard = (value: string) => {
	    const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
	    const matches = v.match(/\d{4,16}/g);
	    const match = (matches && matches[0]) || "";
	    const parts = [];

	    for (let i = 0, len = match.length; i < len; i += 4) {
	        parts.push(match.substring(i, i + 4));
	    }

	    if (parts.length) {
	        return parts.join(" ");
	    } else {
	        return value;
	    }
	};
	/**
	 * Parse a localized number to a float.
	 * @param {string} stringNumber - the localized number
	 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
	 */
	static parseLocaleNumber(stringNumber: string) {
	    const stringFormat = this.getValueLocalStorage("FMNUMBER");
	    const thousandSeparator = Intl.NumberFormat(stringFormat ?? "en-US")
	        .format(11111)
	        .replace(/\p{Number}/gu, "");
	    const decimalSeparator = Intl.NumberFormat(stringFormat ?? "en-US")
	        .format(1.1)
	        .replace(/\p{Number}/gu, "");

	    return parseFloat(
	        stringNumber
	            .replace(new RegExp("\\" + thousandSeparator, "g"), "")
	            .replace(new RegExp("\\" + decimalSeparator), ".")
	    );
	}
	static parseUrl(obj: { [key: string]: string }): URLSearchParams {
	    const params = new URLSearchParams();
	    Object.keys(obj).forEach((key) => {
	        const value = obj[key];
	        if (key && value) {
	            params.set(key, value);
	        }
	    });
	    return params;
	}
	static handleErrosMessaeg(error: any): boolean {
	    if (error) {
	        if (error.status === 401 || error.status === 502) {
	            return false;
	        }
	    }
	    return true;
	}
	static formatDateByUTC(date: Date): Date {
	    const tmp = new Date(date);
	    return new Date(
	        Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0, 0, 0)
	    );
	}
	static getDatesBetween = (
	    startDate: Date,
	    endDate: Date,
	    includeEndDate?: boolean
	) => {
	    const dates = [];
	    const currentDate = startDate;
	    while (currentDate < endDate) {
	        dates.push(format(new Date(currentDate), "MM/dd/yyyy"));
	        currentDate.setDate(currentDate.getDate() + 1);
	    }
	    if (includeEndDate) dates.push(endDate);
	    return dates;
	};
	static getTimeZoneLocal(): number {
	    return new Date().getTimezoneOffset() / 60;
	}
    static monthly = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    static getDaysInMonth(month: number, year: number): Date[] {
        const date = new Date(year, month, 1);
        const days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    }
    static formatDateCallCalendar(date: Date): any | null {
        const tyoeFormat = "yyyy/MM/dd";
        return format(date, tyoeFormat);
    }
    static groupBy(array: any[], key: string): any[] {
        // Return the end result
        const tmp = array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
        const arrayOfObj = Object.entries(tmp).map((e) => ( { child: e[1] } ));
        return arrayOfObj;
    };

		public static stringToColour = (str?: string) => {
			if (!str) return '#ccc';
			let hash = 0;
			for (let i = 0; i < str.length; i++) {
				hash = str.charCodeAt(i) + ((hash << 5) - hash);
			}
			const colour = (hash & 0x00ffffff).toString(16).toUpperCase();
			return '#' + '00000'.substring(0, 6 - colour.length) + colour;
		};
}

export default Utils;

/* eslint-disable */
import SYSTEM_CONSTANTS from "common/constants";
import { Applicant, CreateApplicantRequest, IQueryApplicant, IQueryStats } from "common/models/applicant.model";
import { StatsApplicant } from "common/models/employee.model";
import { IGetAllNumberTimeOff, IPostAllNumberTimeOff, TimeOffItem } from "common/models/timeOffItem.model";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map } from "rxjs/operators";
import HttpClient from "../http-client";

export default class TimeOffItemApi {
    static host = "";

    static GetAbsenceDetailByGuid(guid: string): Observable<Applicant | null> {
        const api = `${TimeOffItemApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_ITEM.GET_BY_GUID}/${guid}`;
        return HttpClient.get(api).pipe(
            map(
                (res) => (res as Applicant) || null,
                catchError((_) => new Observable())
            )
        );
    }
    static GetAbsenceDetailByApplicantGuid(guid: string): Observable<Applicant[] | []> {
        const api = `${TimeOffItemApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_ITEM.GET_ITEM}/${guid}`;
        return HttpClient.get(api).pipe(
            map(
                (res) => (res as Applicant[]) || [],
                catchError((_) => new Observable())
            )
        );
    }

    static updateStatusTimeOffItem(guid: string, data: TimeOffItem): Observable<boolean> {
        const api = `${TimeOffItemApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_ITEM.GET_ITEM}/${guid}`;
        return HttpClient.put(api, data).pipe(
            map(
                (res) => (res as boolean),
                catchError((_) => new Observable())
            )
        );
    }
    static updateStatusListTimeOffItemByApplicant(applicantGuid: string, status: number): Observable<boolean> {
        const api = `${TimeOffItemApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_ITEM.GET_ITEM}`;
        return HttpClient.put(api, { applicantGuid, status }).pipe(
            map(
                (res) => (res as boolean),
                catchError((_) => new Observable())
            )
        );
    }
    static getTimeOffItemByApplicantId(applicantGuid: string): Observable<TimeOffItem[] | []> {
        const api = `${TimeOffItemApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_ITEM.GET_ITEM}/applicant/${applicantGuid}`;
        return HttpClient.get(api).pipe(
            map((res) => res as TimeOffItem[] | [])
        );
    }
    static getAllNumberDayOffByDate(data: IPostAllNumberTimeOff): Observable<IGetAllNumberTimeOff[] | []> {
        const api = `${TimeOffItemApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_ITEM.GET_ITEM}/allNumberDayOff`;
        return HttpClient.post(api, data).pipe(
            map((res) => res as IGetAllNumberTimeOff[] | [])
        );
    }
    static getStatsTimeOffItem(data: IQueryStats): Observable<StatsApplicant[] | []> {
        const api = `${TimeOffItemApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.STATS.GET_STATS_TIME_OFF}`;
        return HttpClient.post(api, data).pipe(
            map((res) => res as StatsApplicant[] | [])
        );
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import AppApi from "api/app";
import { RootEpic } from "common/define-type";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { getListTimeOffTypeRequest, getListTimeOffTypeSuccess } from "./app.slice";


const getListTypeOffType$: RootEpic = (action$) => action$.pipe(
    filter(getListTimeOffTypeRequest.match),
    switchMap((re) => {
        return AppApi.getTimeOffType().pipe(
            mergeMap((res: any) => {
                return [getListTimeOffTypeSuccess(res)];
            }),
            catchError(err => [])
        );
    })
)


export const AppEpics = [
    getListTypeOffType$,
]
import EmployeeApi from "api/employee/employee.api";
import { RootEpic } from "common/define-type";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { messageForgot } from "../subDepartment/subDepartment.slice";
import { getTimeOffLimitRequest, getTimeOffLimitSuccess } from "./timeOff.slice";


const fetchTimeOffLimit$: RootEpic = (action$) => action$.pipe(
    filter(getTimeOffLimitRequest.match),
    switchMap((re) => {
        const { EmployeeGuid, dateForm, dateTo } = re.payload;
        return EmployeeApi.getTimeOffLimitByEmployeeGuid(EmployeeGuid, { dateForm: dateForm, dateTo: dateTo }).pipe(
            map((res) => {
                return getTimeOffLimitSuccess(res);
            }), catchError(err => [messageForgot(err.response)])
        )
    })
)

export const TimeOffEpics = [
    fetchTimeOffLimit$,
]
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Employee, IGetRoleClaim } from "common/models/employee.model";


interface ModalState {
    visibleModalEditAbsence: boolean,
    visibleModalShowAbsence: boolean,
    visibleModalDetailAbsence: boolean,
    visibleModalAddEmployee: boolean,
    isShowTimeLine: boolean,
    visibleModalEditEmployee: boolean,
    visibleModalEditDepartment: boolean,
    visibleModalTransferEmployee: boolean,
    visibleModalOffAssignment: boolean,
    employeeGuid: string,
    name: string,
    visibleModalAuth: boolean,
    visibleStats: boolean,
    listEmployeeGuid: string[],
    isSwapViewHRStaff: boolean,
    listRoleAccount: IGetRoleClaim[],
    listEmployee: Employee[]
}

const initialModalState: ModalState = {
    visibleModalEditAbsence: false,
    visibleModalShowAbsence: false,
    visibleModalDetailAbsence: false,
    visibleModalAddEmployee: false,
    visibleModalEditDepartment: false,
    visibleModalTransferEmployee: false,
    visibleModalOffAssignment: false,
    isShowTimeLine: true,
    visibleModalEditEmployee: false,
    employeeGuid: "",
    name: "",
    visibleModalAuth: false,
    visibleStats: false,
    listEmployeeGuid: [],
    isSwapViewHRStaff: false,
    listRoleAccount: [],
    listEmployee: [],
};

const modalSlice = createSlice({
    name: 'modalSlice',
    initialState: initialModalState,
    reducers: {
        setVisibleModalEditAbsence: (state, action: PayloadAction<{ visibleModalEditAbsence: boolean, employeeGuid?: string, name?: string }>) => {
            state.visibleModalEditAbsence = action.payload.visibleModalEditAbsence;
            if (action.payload.employeeGuid) {
                state.employeeGuid = action.payload.employeeGuid;
            }
            if (action.payload.name) {
                state.name = action.payload.name;
            }
        },
        setVisibleModalAddEmployee: (state, action: PayloadAction<boolean>) => {
            state.visibleModalAddEmployee = action.payload;
        },
        setVisibleModalEditEmployee: (state, action: PayloadAction<{ visibleModalEditEmployee: boolean, employeeGuid?: string, name?: string }>) => {
            state.visibleModalEditEmployee = action.payload.visibleModalEditEmployee;
            if (action.payload.employeeGuid) {
                state.employeeGuid = action.payload.employeeGuid;
            }
            if (action.payload.name) {
                state.name = action.payload.name;
            }
        },
        setVisibleModalShowAbsence: (state, action: PayloadAction<boolean>) => {
            state.visibleModalShowAbsence = action.payload;
        },
        setVisibleModalDetailAbsence: (state, action: PayloadAction<boolean>) => {
            state.visibleModalDetailAbsence = action.payload;
        },
        setIsShowTimeLine: (state, action: PayloadAction<boolean>) => {
            state.isShowTimeLine = action.payload
        },
        setVisibleModalEditDepartment: (state, action: PayloadAction<{ visibleModalEditDepartment: boolean, employeeGuid?: string, name?: string }>) => {
            state.visibleModalEditDepartment = action.payload.visibleModalEditDepartment;
            if (action.payload.employeeGuid) {
                state.employeeGuid = action.payload.employeeGuid;
            }
            if (action.payload.name) {
                state.name = action.payload.name;
            }
        },
        setVisibleModalTransferEmployee: (state, action: PayloadAction<{ visibleModalTransferEmployee: boolean, employeeGuid?: string, name?: string }>) => {
            state.visibleModalTransferEmployee = action.payload.visibleModalTransferEmployee;
            if (action.payload.employeeGuid) {
                state.employeeGuid = action.payload.employeeGuid;
            }
            if (action.payload.name) {
                state.name = action.payload.name;
            }
        },
        setVisibleModalOffAssignment: (state, action: PayloadAction<{ visibleModalOffAssignment: boolean, employeeGuid?: string, name?: string }>) => {
            state.visibleModalOffAssignment = action.payload.visibleModalOffAssignment;
            if (action.payload.employeeGuid) {
                state.employeeGuid = action.payload.employeeGuid;
            }
            if (action.payload.name) {
                state.name = action.payload.name;
            }
        },
        setVisibleModalAuth: (state, action: PayloadAction<boolean>) => {
            state.visibleModalAuth = action.payload
        },
        setVisibleStats: (state, action: PayloadAction<boolean>) => {
            state.visibleStats = action.payload
        },
        setListEmployeeGuid: (state, action: PayloadAction<string[]>) => {
            state.listEmployeeGuid = action.payload
        },
        setIsSwapViewHrStaff: (state, action: PayloadAction<boolean>) => {
            state.isSwapViewHRStaff = action.payload
        },
        setListRoleAccount: (state, action: PayloadAction<{ dataRole: IGetRoleClaim[], dataEmployee: Employee[] }>) => {
            state.listRoleAccount = action.payload.dataRole;
            state.listEmployee = action.payload.dataEmployee;
        }
    }
})

export const { setVisibleModalEditAbsence, setVisibleModalAddEmployee, setVisibleModalShowAbsence,setVisibleModalDetailAbsence, setIsShowTimeLine, setVisibleModalEditEmployee, setVisibleModalEditDepartment, setVisibleModalAuth, setVisibleStats, setListEmployeeGuid, setVisibleModalTransferEmployee, setVisibleModalOffAssignment, setIsSwapViewHrStaff, setListRoleAccount } = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
import HttpClient from "api/http-client";
import SYSTEM_CONSTANTS from "common/constants";
import { IGetDepartmentDTO, IPostDepartmentDTO, IUpdateDepartmentDTO } from "common/models/department.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

class DepartmentApi{
    static host = ""
    static getListDepartmentByParentId(companyId: string): Observable<IGetDepartmentDTO[] | []> {
        return HttpClient.get(`${DepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.DEPARTMENT_SERVICE.DEPARTMENT}/${companyId}`).pipe(
            map((res) => res as IGetDepartmentDTO[] | [])
        );
    }
    static createDepartmentByParentId(data: IPostDepartmentDTO): Observable<boolean | null> {
        const url = `${DepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.DEPARTMENT_SERVICE.DEPARTMENT}`
        return HttpClient.post(url, data).pipe(
            map((res) => res as boolean | null)
        );
    }
    static deleteDepartmentById(departmentId: string): Observable<IGetDepartmentDTO[] | []> {
        const url = `${DepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.DEPARTMENT_SERVICE.DEPARTMENT}/${departmentId}`;
        return HttpClient.delete(url).pipe(
            map((res) => res as IGetDepartmentDTO[] | [])
        );
    }
    static updateDepartmentById(data: IUpdateDepartmentDTO): Observable<IGetDepartmentDTO[] | []> {
        const url = `${DepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.DEPARTMENT_SERVICE.DEPARTMENT}/${data.GUID}`;
        return HttpClient.put(url, data).pipe(
            map((res) => res as IGetDepartmentDTO[] | [])
        );
    }
}
export default DepartmentApi
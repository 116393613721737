import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITypeOff } from "common/define-timeoff/define-timeoff";
import { LanguageType, NavbarType } from "common/define-type";
import GLobalPkm from "common/global";

interface AppState {
    language: LanguageType;
    navbar: NavbarType;
    companyId: string;
    departmentGuid: string;
    timeOffType: ITypeOff[];
    refresh: boolean,
    hrmsSync: boolean
}

const initAppState: AppState = {
    language: "vi",
    navbar: "dashboard",
    companyId: "326f2790-dbd3-11ec-aa3d-0242ac180002",
    departmentGuid: GLobalPkm.defaultBytes32,
    timeOffType: [],
    refresh: false,
    hrmsSync: false
};

const appSlice = createSlice({
    name: "app",
    initialState: initAppState,
    reducers: {
        changeLanguage(state, action: PayloadAction<LanguageType>) {
            state.language = action.payload;
        },
        setNavbar(state, action: PayloadAction<NavbarType>) {
            const navbar = action.payload;
            if (navbar !== state.navbar) {
                state.navbar = action.payload;
            }
        },
        getListTimeOffTypeRequest(state, action: PayloadAction<string>) {
            state.timeOffType = [];
        },
        getListTimeOffTypeSuccess(state, action: PayloadAction<ITypeOff[]>) {
            state.timeOffType = action.payload;
        },
        setDepartment(state, action: PayloadAction<string>) {
            state.departmentGuid = action.payload;
        },
        setRefresh(state) {
            state.refresh = state.refresh ? false : true;
        },
        setHrmsSync(state, action) {
            state.hrmsSync = action.payload;
        }
    },
});

export const {
    changeLanguage,
    setNavbar,
    getListTimeOffTypeRequest,
    getListTimeOffTypeSuccess,
    setDepartment,
    setRefresh,
    setHrmsSync
} = appSlice.actions;

export const appReducer = appSlice.reducer;

/* eslint-disable */
import SYSTEM_CONSTANTS from "common/constants";
import {
    NewResponseLogin
} from "common/define-identity";
import JSEncrypt from "jsencrypt";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map } from "rxjs/operators";
import HttpClient from "../http-client";
export default class IdentityApi {
    static host = "";
    static encryptData(text: string, key: string) {
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(key);
        const encypt = jsEncrypt.encrypt(text);
        return encypt || "";
    }
    static login(body: any): Observable<NewResponseLogin | null> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.LOGIN}`;
        return HttpClient.post(api, body).pipe(
            map(
                (res) => (res as NewResponseLogin) || null,
                catchError((error) => new Observable())
            )
        );
    }

    static forgotPassword(email: string): Observable<any | null> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.FORGOT}/${email}/notify/passwordreset`;
        return HttpClient.post(api, {}).pipe(
            map(
                (res) => (res as any) || null,
                catchError((error) => new Observable())
            )
        );
    }
    static disableAccount(accountGuid: string): Observable<boolean | null> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.DELETE_ACCOUNT}/${accountGuid}`;
        return HttpClient.delete(api).pipe(
            map(
                (res) => res as boolean | null
            ));
    }

    static getAccountRoles(accountGuid: string): Observable<any | null> {
        const api = `${IdentityApi.host}/api/auth/getAccountRoles/${accountGuid}`;
        return HttpClient.post(api, {}).pipe(
            map(
                (res) => (res as any) || null,
                catchError((error) => new Observable())
            )
        );
    }
}

import React from 'react';
import './App.scss';
import './theme/theme.less';
import CMainRouter from 'components/CMainRouter';
import CBootstrap from 'components/CBootstrap';
import { CThemeProvider } from 'theme';
import 'i18n/i18next-config';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from 'redux/store';
import 'react-calendar-timeline/lib/Timeline.css';
import 'moment/locale/vi';
// import useWindowSize from 'hooks/useWindowSize';
// import { deviceScreen } from 'common/enum/shared.enum';
// import { MobileView } from 'Views';

function App(): JSX.Element {
  // const size = useWindowSize();

  return (
    <PersistGate persistor={persistor}>
      <CBootstrap>
        <CThemeProvider>
          <CMainRouter />
        </CThemeProvider>
      </CBootstrap>
    </PersistGate>
  );
}

export default App;

import HttpClient from "api/http-client";
import SYSTEM_CONSTANTS from "common/constants";
import { IGetSubDepartmentDTO } from "common/models/subDepartment.model";
import { IPostTimeOffType, IUpdateTimeOffType } from "common/models/timeOffType.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

class AppApi {
    static host = ""
    static getTimeOffType(): Observable<IGetSubDepartmentDTO[] | []> {
        return HttpClient.get(`${AppApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_TYPE.GET_TYPE}`).pipe(
            map((res) => res as IGetSubDepartmentDTO[] | [])
        );
    }
    static createTimeOffType(data: IPostTimeOffType): Observable<boolean | null> {
        return HttpClient.post(`${AppApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_TYPE.GET_TYPE}`, data).pipe(
            map((res) => res as boolean | null)
        );
    }
    static deleteTimeOffType(GUID: string):Observable<boolean | null> {
        return HttpClient.delete(`${AppApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_TYPE.GET_TYPE}/${GUID}`).pipe(
            map((res) => res as boolean | null)
        );
    }
    static updateTimeOffType(data: IUpdateTimeOffType, GUID: string): Observable<boolean | null> {
        return HttpClient.put(`${AppApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.TIME_OFF_TYPE.GET_TYPE}/${GUID}`, data).pipe(
            map((res) => res as boolean | null)
        );
    }
    static hrmsSyncApi(): Observable<any> {
        return HttpClient.post(`${AppApi.host}/api/sync/hrms`, {}).pipe(
            map((res) => res)
        );
    }
}
export default AppApi
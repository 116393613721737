import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListDataChart } from "common/define-timeoff/define-timeoff";
import { IGetTimeOffLimmit } from "common/models/employee.model";
import { IDispatchTimeOffLimit } from "common/models/timeOffType.model";

interface TimeOffState {
    loading: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    groups: any[],
    timeOffLimit: IGetTimeOffLimmit | null
}

const initTimeOffState: TimeOffState = {
    loading: false,
    items: [],
    groups: [{
        id: 1,
        title: "group 1",
        rightTitle: "title in the right sidebar",
        stackItems: true,
        height: 30,
    }],
    timeOffLimit: null
}

const timeOffSlice = createSlice({
    name: 'timeOff',
    initialState: initTimeOffState,
    reducers: {
        getDataChartRequest(state, action: PayloadAction<ListDataChart>) {
            state.items = action.payload.listApplicantByEmployee
            state.groups = action.payload.listDataEmployee
        },
        getTimeOffLimitRequest(state, action: PayloadAction<IDispatchTimeOffLimit>) {
            state.loading = true
        },
        getTimeOffLimitSuccess(state, action: PayloadAction<IGetTimeOffLimmit>) {
            state.timeOffLimit = action.payload
        },
    }
})

export const {
    getDataChartRequest,
    getTimeOffLimitRequest,
    getTimeOffLimitSuccess
} = timeOffSlice.actions;


export const timeOffReducer = timeOffSlice.reducer
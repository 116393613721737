import HttpClient from "api/http-client";
import SYSTEM_CONSTANTS from "common/constants";
import { IPostDepartmentDTO } from "common/models/department.model";
import {
    ICreateSubDepartmemtDTO,
    IGetSubDepartmentDTO,
    InforDepartment,
    IUpdateSubDepartmemtDTO,
} from "common/models/subDepartment.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

class SubDepartmentApi {
    static host = ""
    static getListDepartmentByParentCode(guid: string): Observable<IGetSubDepartmentDTO[] | []> {
        return HttpClient.get(`${SubDepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.SUBDEPARTMENT.SUBDEPARTMENT}/${guid}`).pipe(
            map((res) => res as IGetSubDepartmentDTO[] | [])
        );
    }
    static createDepartmentByParentId(data: IPostDepartmentDTO): Observable<boolean | null> {
        const url = `${SubDepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.DEPARTMENT_SERVICE.DEPARTMENT}`
        return HttpClient.post(url, data).pipe(
            map((res) => res as boolean | null)
        );
    }
    static getInforDepartmentByParentCode(guid: string): Observable<InforDepartment[] | []> {
        return HttpClient.get(`${SubDepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.SUBDEPARTMENT.SUBDEPARTMENT}/${guid}`).pipe(
            map((res) => res as InforDepartment[] | [])
        );
    }
    static getListCodeDepartment(guid: string): Observable<IGetSubDepartmentDTO[] | []> {
        return HttpClient.get(`${SubDepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.SUBDEPARTMENT.GET_ALL_DEPARTMENT}/${guid}`).pipe(
            map((res) => res as IGetSubDepartmentDTO[] | [])
        );
    }
    static createSubDepartment(data: ICreateSubDepartmemtDTO): Observable<string | null> {
        return HttpClient.post(`${SubDepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.SUBDEPARTMENT.CM_SUB_DEPARTMENT}`, data).pipe(
            map((res) => res as string | null)
        );
    }
    static deleteSubDepartment(guid: string): Observable<boolean | null> {
        return HttpClient.delete(`${SubDepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.SUBDEPARTMENT.CM_SUB_DEPARTMENT}/${guid}`).pipe(
            map((res) => res as boolean | null)
        );
    }
    static updateSubDepartment(data: IUpdateSubDepartmemtDTO, guid: string): Observable<boolean | null> {
        return HttpClient.put(`${SubDepartmentApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.SUBDEPARTMENT.CM_SUB_DEPARTMENT}/${guid}`, data).pipe(
            map((res) => res as boolean | null)
        );
    }
}
export default SubDepartmentApi;

import HttpClient from "api/http-client";
import SYSTEM_CONSTANTS from "common/constants";
import { IGetReasonTimeOff, IPostReasonTimeOff, IUpdateReasonTimeOff } from "common/models/reasonTimeOff";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

class ReasonTimeOffApi {
    static host = ""
    static getTimeOffType(typeOffTypeId: string): Observable<IGetReasonTimeOff[] | []> {
        return HttpClient.get(`${ReasonTimeOffApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.REASON_TIME_OF.GET_REASON}/${typeOffTypeId}`).pipe(
            map((res) => res as IGetReasonTimeOff[] | [])
        );
    }
    static getListTimeOffType(): Observable<IGetReasonTimeOff[] | []> {
        return HttpClient.get(`${ReasonTimeOffApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.REASON_TIME_OF.GET_REASON}`).pipe(
            map((res) => res as IGetReasonTimeOff[] | [])
        );
    }

    static createReasonTimeOff(data: IPostReasonTimeOff): Observable<boolean | null> {
        const url = `${ReasonTimeOffApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.REASON_TIME_OF.GET_REASON}`
        return HttpClient.post(url, data).pipe(
            map((res) => res as boolean | null)
        );
    }
    static deleteReasonTimeOff(typeOffTypeId: string): Observable<IGetReasonTimeOff[] | []> {
        return HttpClient.delete(`${ReasonTimeOffApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.REASON_TIME_OF.GET_REASON}/${typeOffTypeId}`).pipe(
            map((res) => res as IGetReasonTimeOff[] | [])
        );
    }
    static updateReasonTimeOffById(data: IUpdateReasonTimeOff, ReasonTimeOffID: string): Observable<IUpdateReasonTimeOff[] | []> {
        const url = `${ReasonTimeOffApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.REASON_TIME_OF.GET_REASON}/${ReasonTimeOffID}`;
        return HttpClient.put(url, data).pipe(
            map((res) => res as IUpdateReasonTimeOff[] | [])
        );
    }
}
export default ReasonTimeOffApi
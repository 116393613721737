import DepartmentApi from "api/department/department.api";
import { RootEpic } from "common/define-type";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { getDepartmentRequest, getDepartmentSuccess, messageForgot } from "./department.slice";


const getDepartment$: RootEpic = (action$) => action$.pipe(
    filter(getDepartmentRequest.match),
    switchMap((re) => {
        return DepartmentApi.getListDepartmentByParentId(re.payload).pipe(
            map((res) => {
                return getDepartmentSuccess(res);
            }), catchError(err => [messageForgot(err.response)])
        )
    })
)

export const DepartmentEpics = [
    getDepartment$,
]
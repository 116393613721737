import { RootEpic } from "common/define-type";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { getSubDepartmentRequest, getSubDepartmentSuccess, messageForgot } from "./subDepartment.slice";
import SubDepartmentApi  from 'api/subDepartment/subDepartment.api';


const getSubDepartment$: RootEpic = (action$) => action$.pipe(
    filter(getSubDepartmentRequest.match),
    switchMap((re) => {
        return SubDepartmentApi.getListCodeDepartment(re.payload).pipe(
            map((res) => {
                return getSubDepartmentSuccess(res);
            }), catchError(err => [messageForgot(err.response)])
        )
    })
)

export const SubDepartmentEpics = [
    getSubDepartment$,
]